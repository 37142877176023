
import { useTranslation } from "react-i18next"
import { ContainerSection, Img, P, Title } from "./Common"

const className = {
    dataContainer: 'flex flex-col items-start w-full lg:w-1/2 gap-5 pe-5',
}

export const KeldCompanySection = () => {
    const { t } = useTranslation()

    return (
        <ContainerSection className="justify-center">
            <div className={className.dataContainer}>
                <Title text={t('KeldCompanySectionT')} />
                <P >
                    <div className="mb-[24px]">
                        {t('KeldCompanySectionD1')}
                    </div>
                    <div>
                        {t('KeldCompanySectionD2')}
                    </div>
                </P>
            </div>
            <Img src={'/images/home/image-004.jpg'} className="lg:h-[80vh] h-[50vh] order-2" />
        </ContainerSection>
    )
}
