import { DisclosureButton } from "@headlessui/react"

const className = {
    button: `px-2 py-1 ms-auto text-gray-500 rounded-md lg:hidden hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:text-gray-300 dark:focus:bg-trueGray-700`
}

const OpenBtn = () => <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
/>

const ClosedBtn = () => <path
    fillRule="evenodd"
    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
/>

export const Icon = ({ open }: { open: boolean }) => {
    return (
        <DisclosureButton
            aria-label="Toggle Menu"
            className={className.button}>
            <svg
                className="w-6 h-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                {open && <OpenBtn />}
                {!open && <ClosedBtn />}
            </svg>
        </DisclosureButton>
    )
}
