import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  Disclosure,
  Transition,
  DisclosurePanel,
} from "@headlessui/react";
import { Button } from "./Button";
import { Title } from "./Title";
import { IsError } from "./IsError";
import { IsSuccess } from "./IsSuccess";
import { useTranslation } from "react-i18next";

const API_KEY = '9a24ad50-bef6-4d8b-a7b5-fce008c1bc3f'

export function PopupWidget() {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm({
    mode: "onTouched",
  });

  const { t } = useTranslation()

  const [isSuccess, setIsSuccess] = useState(false);
  const [Message, setMessage] = useState("");

  const userName = useWatch({ control, name: "name", defaultValue: "Someone" });

  const onSubmit = async (data: any, e: any) => {
    console.log(data);
    await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data, null, 2),
    })
      .then(async (response) => {
        let json = await response.json();
        if (json.success) {
          setIsSuccess(true);
          setMessage(json.message);
          e.target.reset();
          reset();
        } else {
          setIsSuccess(false);
          setMessage(json.message);
        }
      })
      .catch((error) => {
        setIsSuccess(false);
        setMessage("Client Error. Please check the console.log for more info");
        console.log(error);
      });
  };

  return (
    <div>
      <Disclosure>
        {({ open }) => (
          <>
            <Button open={open} />
            <Transition
              className="fixed z-50 bottom-[100px] top-0 start-0 end-0 sm:top-auto sm:end-5 sm:start-auto"
              enter="transition duration-200 transform ease"
              enterFrom="opacity-0 translate-y-5"
              leave="transition duration-200 transform ease"
              leaveTo="opacity-0 translate-y-5"
              as="div"
            >
              <DisclosurePanel className=" flex flex-col overflow-hidden left-0 h-full w-full sm:w-[350px] min-h-[250px] sm:h-[600px] border border-gray-300 dark:border-gray-800 bg-white shadow-2xl rounded-md sm:max-h-[calc(100vh-120px)]">
                <Title />
                <div className="flex-grow h-full p-6 overflow-auto bg-gray-50 ">
                  {!isSubmitSuccessful && (
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                      <input
                        type="hidden"
                        value={API_KEY}
                        {...register('apikey')}
                      />
                      <input
                        type="hidden"
                        value={`${userName} sent a message from Keld.sa`}
                        {...register("subject")}
                      />
                      <input
                        type="hidden"
                        value="Keld.sa Template"
                        {...register("from_name")}
                      />
                      <input
                        type="checkbox"
                        className="hidden"
                        style={{ display: "none" }}
                        {...register("botcheck")}
                      ></input>

                      <div className="mb-4">
                        <label
                          htmlFor="full_name"
                          className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                        >
                          {t('fullName')}
                        </label>
                        <input
                          type="text"
                          id="full_name"
                          placeholder="John Doe"
                          {...register("name", {
                            required: t("fullNameReq"),
                            maxLength: 80,
                          })}
                          className={`w-full px-3 py-2 text-gray-600 placeholder-gray-300 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring   ${errors.name
                            ? "border-red-600 focus:border-red-600 ring-red-100"
                            : "border-gray-300 focus:border-indigo-600 ring-indigo-100"
                            }`}
                        />
                        {errors.name && (
                          <div className="mt-1 text-sm text-red-400 invalid-feedback">
                            {errors.name.message as string}
                          </div>
                        )}
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                        >
                          {t('email')}
                        </label>
                        <input
                          type="email"
                          id="email"
                          {...register("email", {
                            required: t('emailReq'),
                            pattern: {
                              value: /^\S+@\S+$/i,
                              message: t('emailValid'),
                            },
                          })}
                          placeholder="you@company.com"
                          className={`w-full px-3 py-2 text-gray-600 placeholder-gray-300 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring   ${errors.email
                            ? "border-red-600 focus:border-red-600 ring-red-100"
                            : "border-gray-300 focus:border-indigo-600 ring-indigo-100"
                            }`}
                        />

                        {errors.email && (
                          <div className="mt-1 text-sm text-red-400 invalid-feedback">
                            {errors.email.message as string}
                          </div>
                        )}
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="message"
                          className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                        >
                          {t('yourMessage')}
                        </label>

                        <textarea
                          rows={4}
                          id="message"
                          {...register("message", {
                            required: t('yourMessageReq'),
                          })}
                          placeholder={t('yourMessage')}
                          className={`w-full px-3 py-2 text-gray-600 placeholder-gray-300 bg-white border border-gray-300 rounded-md h-28 focus:outline-none focus:ring   ${errors.message
                            ? "border-red-600 focus:border-red-600 ring-red-100"
                            : "border-gray-300 focus:border-indigo-600 ring-indigo-100"
                            }`}
                          required
                        ></textarea>
                        {errors.message && (
                          <div className="mt-1 text-sm text-red-400 invalid-feedback">
                            {errors.message.message as string}
                          </div>
                        )}
                      </div>

                      <div className="mb-3">
                        <button
                          type="submit"
                          className="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none"
                        >
                          {isSubmitting ? (
                            <svg
                              className="w-5 h-5 mx-auto text-white animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : (
                            t('send')
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                  {isSubmitSuccessful && isSuccess && <IsSuccess Message={Message} reset={reset} />}
                  {isSubmitSuccessful && !isSuccess && <IsError Message={Message} reset={reset} />}
                </div>
              </DisclosurePanel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}
