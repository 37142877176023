import { ContainerSection, Img, Title } from './Common'
import { useTranslation } from 'react-i18next'

const className = {
    dataContainer: 'p-10 mb-8 flex flex-col items-start w-full lg:w-1/2 gap-5 pe-5',
    h1: `font-bold leading-snug tracking-tight text-gray-800 border-b-8 border-indigo-500
    lg:leading-tight xl:leading-tight dark:text-white`,
    p: `py-2 leading-normal text-gray-800 lg:text-xl sm:text-sm text-[10px]`,
}


export const ResidentialCommunities = () => {
    const { t } = useTranslation()

    return (
        <ContainerSection padding="0" className="bg-yellow-500">
            <div className={className.dataContainer}>
                <Title text={t('ResidentialCommunitiesT')} />
                <p className={className.p}>
                    <div className="mb-[24px]">
                        {t('ResidentialCommunitiesD1')}
                    </div>
                    <div className="mb-[24px]">
                        {t('ResidentialCommunitiesD2')}
                    </div>
                    <div>
                        {t('ResidentialCommunitiesD3')}
                    </div>
                </p>
            </div>
            <Img src={'/images/home/image-010.jpg'} className="order-2 lg:h-[100vh] h-[50vh]" />
        </ContainerSection>
    )
}
