import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { America, Saudi } from './Icons'
import { LangContext } from '../../../Context/LangContext'
import { useContext } from 'react'

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const className = {
    menuButton: `inline-flex w-full justify-center gap-x-1.5 
    rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 
    shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`,
    container: `absolute z-10 sm:end-0 mt-2 w-56 origin-top-right rounded-md
     bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition 
     focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
     data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in`,
    menuItem: 'px-4 py-2 text-sm flex items-center justify-between cursor-pointer'

}

export const Language = () => {

    const langContext = useContext(LangContext);

    if (!langContext) {
        throw new Error('ThemeContext must be used within a ThemeProvider');
    }

    const { changeLang, lang } = langContext;

    return (
        <Menu as="div" className="relative inline-block text-center w-32">
            <div>
                <MenuButton className={className.menuButton}>
                    {lang === 'ar' ? "اللغة" : "Language"}
                    <ChevronDownIcon className="mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </MenuButton>
            </div>

            <MenuItems className={className.container} >
                <div className="py-1">
                    <MenuItem>
                        {({ focus }) => (
                            <div
                                onClick={() => {
                                    changeLang('ar')
                                }}
                                className={classNames(focus || lang === 'ar' ? 'bg-gray-100 text-gray-900' :
                                    'text-gray-700', className.menuItem)}
                            >
                                {lang === 'ar' ? "العربية" : "Arabic"}
                                <Saudi />
                            </div>
                        )}
                    </MenuItem>
                    <MenuItem>
                        {({ focus }) => (
                            <div
                                onClick={() => {
                                    changeLang('en')
                                }}
                                className={classNames(focus || lang === 'en' ? 'bg-gray-100 text-gray-900' :
                                    'text-gray-700', className.menuItem)}
                            >
                                {lang === 'ar' ? "الانجليزية" : "English"}
                                <America />
                            </div>
                        )}
                    </MenuItem>

                </div>
            </MenuItems>
        </Menu>
    )
}
