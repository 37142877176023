import { Route, Routes } from 'react-router-dom'
import { Home } from './Pages/Home'

export const Router = () => {
    return (
        <Routes>
            <Route
                path="/"
                Component={Home}
            />
        </Routes>
    )
}
