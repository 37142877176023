import { ContainerSection, Img, P, Title } from "./Common"
import React from "react";
import {
    FaceSmileIcon,
    ChartBarSquareIcon,
    CursorArrowRaysIcon,
} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

const className = {
    dataContainer: 'flex flex-col items-start lg:w-1/2 px-5 order-1 lg:order-3',
}

export const Introduction = () => {
    const { t } = useTranslation()

    const list = [
        {
            title: t('intro1'),
            icon: <FaceSmileIcon />,
        },
        {
            title: t('intro2'),
            icon: <ChartBarSquareIcon />,
        },
        {
            title: t('intro3'),
            icon: <CursorArrowRaysIcon />,
        },
    ]

    return (
        <ContainerSection padding="0" className="bg-yellow-500">
            <Img src={'/images/home/image-002.jpg'} className="order-2 lg:h-[100vh] h-[50vh]" />
            <div className={className.dataContainer}>
                <Title text={t('intro')} textLg />
                <P>
                    <div className="w-full">
                        {list.map((item, index) => (
                            <Section key={index} title={item.title} icon={item.icon}>
                                {item.title}
                            </Section>
                        ))}
                    </div>
                </P>
            </div>
        </ContainerSection>
    )
}

const Section = (props: any) => {
    return (
        <div className="flex items-center mt-8 gap-3">
            <div className="flex items-center justify-center flex-shrink-0 mt-1 bg-indigo-500 rounded-md w-9 h-9 ">
                {React.cloneElement(props.icon, {
                    className: "w-5 h-5 text-indigo-50",
                })}
            </div>
            <div>
                <h4 className="font-medium text-gray-800">
                    {props.title}
                </h4>
            </div>
        </div>
    );
}