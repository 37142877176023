import { motion } from "framer-motion";
import { ContainerSection, Img, P, Title } from "./Common"
import { useMemo } from "react";
import { ScrollAnimationWrapper, getScrollAnimation } from "../Common/Layout/ScrollAnimation";
import { useTranslation } from "react-i18next";

const className = {
    dataContainer: `flex flex-col w-full lg:w-1/2 gap-5 pe-5 justify-center
    `,
}

export const Hero1 = () => {
    const scrollAnimation = useMemo(() => getScrollAnimation(), []);
    const { t } = useTranslation()

    return (
        <ScrollAnimationWrapper>
            <motion.div variants={scrollAnimation} >
                <ContainerSection className="justify-center">
                    <div className={className.dataContainer}>
                        <Title text={"Keld"} textLg />
                        <P>{t('welcome')}</P>
                    </div>
                    <Img src={'/images/home/image-001.jpg'} />
                </ContainerSection>
            </motion.div >
        </ScrollAnimationWrapper>
    )
}
