import { SectionWithBgImg } from "./Common"
import { useTranslation } from "react-i18next"


export const SectionWithBgImg1 = () => {
    const { t } = useTranslation()

    return (
        <SectionWithBgImg bgImage="/images/home/image-006.jpg">
            <span>
                {t("SectionWithBgImg1")}
            </span>
            <span>
                {t("SectionWithBgImg2")}
            </span>
        </SectionWithBgImg>
    )
}
