import { useTranslation } from "react-i18next"
import { ContainerSection, Img, P, Title } from "./Common"

const className = {
    dataContainer: 'flex flex-col items-start w-full lg:w-1/2 gap-5 p-5',
}

export const RealEstateInfrastructure = () => {
    const { t } = useTranslation()

    return (
        <ContainerSection padding="0" className="bg-yellow-500">
            <Img src={'/images/home/image-007.jpg'} className="lg:h-[100vh] h-[50vh]" />
            <div className={className.dataContainer}>
                <Title text={t('RealEstateInfrastructure')} />
                <div className="lg:text-xl sm:text-sm text-[10px]">
                    <div className="mb-[24px]">
                        {t('RealEstateInfrastructureD')}
                    </div>
                    <div className="mb-[24px]">
                        {t('RealEstateInfrastructureD2')}
                    </div>
                    <div>
                        {t('RealEstateInfrastructureD3')}
                    </div>
                </div>
            </div>
        </ContainerSection>
    )
}
