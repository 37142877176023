import { Disclosure } from "@headlessui/react";
import { ThemeChanger } from "../DarkSwitch";
import { Menu, SmallMenu } from "./Menu";
import { Logo } from "./Logo";
import { Icon } from "./Icon";
import { Language } from "./Language/Language";
import { useBackground } from "../../hooks/useBackground";

const className = {
  container: `z-50 fixed top-0 w-full h-[15vh] flex flex-wrap items-center xl:px-[200px] px-[50px]`
}


export const Navbar = () => {
  const { backgroundColor } = useBackground()

  return (
    <div className={className.container}
      style={{ backgroundColor }}
    >
      <nav className="w-full relative flex flex-wrap items-center justify-between">
        <Disclosure>
          {({ open }) => (
            <>
              <div className="flex flex-wrap items-center justify-between w-full lg:w-auto">
                <Logo />
                <Icon open={open} />
                <SmallMenu />
              </div>
            </>
          )}
        </Disclosure>
        <Menu />
        <div className="hidden gap-3 lg:flex nav__item">
          <ThemeChanger />
          <Language />
        </div>
      </nav >
    </div>
  );
}

