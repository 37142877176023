import { useTranslation } from "react-i18next"

export const Title = () => {
    const { t } = useTranslation()

    return (
        <div className="flex flex-col items-center justify-center h-32 p-5 bg-indigo-600">
            <h3 className="text-lg text-white">{t('helpTitle')}</h3>
            <p className="text-white opacity-50">
                {t('helpSub')}
            </p>
        </div>
    )
}
