import { useTranslation } from 'react-i18next'
import { ContainerSection, P, Title } from './Common'

const images = [
    '/images/portfolio/1.png',
    '/images/portfolio/2.png',
    '/images/portfolio/3.png',
    '/images/portfolio/4.png',
    '/images/portfolio/5.svg',
    '/images/portfolio/6.png',
]

const className = {
    dataContainer: 'flex flex-col items-center text-center gap-5',
}

export const InvestmentsPortfolio = () => {
    const { t } = useTranslation()

    return (
        <ContainerSection className='justify-center border-b py-10 border-black dark:border-trueGray-700'>
            <div className={className.dataContainer}>
                <Title text={t('investments')} />
                <P className=''>
                    {t('investmentsDesc')}
                </P>
            </div>
            <div className="w-full grid lg:grid-cols-3 grid-cols-2 gap-10 items-center justify-items-center">
                {images.map((image) => (
                    <img
                        src={image}
                        width={150}
                        className={"object-cover"}
                        alt={image}
                        loading="eager"
                    />
                ))}
            </div>
        </ContainerSection>
    )
}
