import { useTranslation } from 'react-i18next'
import { P, Title } from './Common'

export const CommercialRealEstate = () => {
    const { t } = useTranslation()
    return (
        <div className={`justify-center flex flex-col text-center items-center gap-4 py-5
        xl:px-[200px] px-[50px]`} >
            <Title text={t('commercialRealEstateT')} />
            <P className='text-center'>
                <div>
                    {t('commercialRealEstateD')}
                </div>
                <div>
                    {t('commercialRealEstateD2')}
                </div>
                <div>
                    {t('commercialRealEstateD3')}
                </div>
            </P>
        </div >
    )
}
