
import { Footer } from "./Components/Footer/Footer"
import { Navbar } from "./Components/Navbar/Navbar"
import { PopupWidget } from "./Components/Sections/HowCanWeHelp/PopupWidget"

import { Router } from "./Router"
import { Container } from "./Components/Common/Containers/Container"

export const App = () => {

  return (
    <>
      <Navbar />
      <Container>
        <Router />
        <Footer />
        <PopupWidget />
      </Container>
    </>
  )
}
