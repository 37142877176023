import { useContext } from "react";
import { Logo } from "../Navbar/Logo";
import { CopyRight } from "./CopyRight";
import { ThemeContext } from "../../Context/ThemeContext";
import { DATA } from "./Data";

const className = {
  container: `text-gray-500 dark:text-gray-400 w-full
 gap-10 py-10 border-t border-black dark:border-trueGray-700`,
  footerContainer: `flex flex-col gap-5 my-3
  justify-between items-center w-full lg:flex-row lg:justify-center`,
  phone: "w-[200px] text-center",
  st: "w-[350px] text-center",
  img: "w-[100px] h-[100px]"
}

export function Footer() {

  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error('ThemeContext must be used within a ThemeProvider');
  }
  const { theme } = themeContext;

  const qr = theme === 'dark' ? DATA.imgDark : DATA.imgLight

  return (
    <div className={className.container}>
      <div className={className.footerContainer}>
        <Logo />
        <div className={className.phone}>
          {DATA.phone}
        </div>
        <div>{DATA.info}</div>
        <div>{DATA.www}</div>
        <div className={className.st}>
          {DATA.st}
        </div>
        <img src={qr} alt={qr} className={className.img} />
      </div>
      <CopyRight />
    </div >
  );
}