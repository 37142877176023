import { motion } from "framer-motion";

type ChildrenProp = {
    children: React.ReactNode;
    className?: string
}

export const ScrollAnimationWrapper = ({ children, className, ...props }: Readonly<ChildrenProp>) => {
    return (
        <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
            className={className}
            {...props}
        >
            {children}
        </motion.div>
    )
}

export const getScrollAnimation = () => {
    return ({
        offscreen: {
            y: 150,
            opacity: 0,
        },
        onscreen: ({ duration = 2 } = {}) => ({
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                duration,
            }
        })
    })
}