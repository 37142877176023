// LangContext.tsx
import { createContext, useState, ReactNode, FC } from 'react';
import i18next from '../i18n';

type Lang = 'ar' | 'en';

interface LangContextType {
    lang: Lang;
    changeLang: (lang: Lang) => void;
}

// Create the context with default values
export const LangContext = createContext<LangContextType | undefined>(undefined);

interface LangProviderProps {
    children: ReactNode;
}

// Create a provider component
export const LangProvider: FC<LangProviderProps> = ({ children }) => {
    const [lang, setLang] = useState<Lang>(localStorage['lang'] ?? 'en');

    const changeLang = (lang: Lang) => {
        i18next.changeLanguage(lang)
        setLang(lang);
        localStorage['lang'] = lang
    };

    return (
        <LangContext.Provider value={{ lang, changeLang }}>
            {children}
        </LangContext.Provider>
    );
};

