import { Img, P } from "./Common";
import { useTranslation } from "react-i18next";

const className = {
    container: 'flex flex-wrap flex-col w-[80%] gap-10 mx-auto lg:gap-10 lg:flex-nowrap'
}
export const Ai = () => {
    const { t } = useTranslation()
    return (
        <div className={className.container}>
            <Img
                width="100vw"
                className={"object-cover w-full h-[50vh] bg-fixed"}
                src={'/images/home/image-005.jpg'}
            />
            <P className={`lg:text-2xl md:text-xl sm:text-base 
                min-[320px]:text-sm min-[250px]:text-[12px] min-[200px]:text-[10px] 
                text-[10px] 
                py-5 h-[80vh]`}>
                {t("aiP")}
            </P>
        </div >
    );
}
