import { Ai } from "../Components/Sections/Ai";
import { InvestmentsPortfolio } from "../Components/Sections/InvestmentsPortfolio";
import { CommercialRealEstate } from "../Components/Sections/CommercialRealEstate";
import { SectionWithBgImg1 } from "../Components/Sections/SectionWithBgImg1";
import { SectionWithBgImg2 } from "../Components/Sections/SectionWithBgImg2";
import { Hero1 } from "../Components/Sections/Hero1";
import { KeldCompanySection } from "../Components/Sections/KeldCompanySection";
import { Introduction } from "../Components/Sections/Introduction";
import { ContainerSection, Title } from "../Components/Sections/Common";
import { ResidentialCommunities } from "../Components/Sections/ResidentialCommunities";
import { RealEstateInfrastructure } from "../Components/Sections/RealEstateInfrastructure";
import { useTranslation } from "react-i18next";
import Lenis from 'lenis';
import { useEffect, useRef } from "react";
import { useScroll } from "framer-motion";
import { AnimatedSection, Container } from "../Components/Common/Containers/Container";

export const Home = () => {
    const { t } = useTranslation()

    const container = useRef<any>();
    const { scrollYProgress } = useScroll({
        target: container,
        offset: ["start start", "end end"]
    })

    const fContainer = useRef<any>();
    const { scrollYProgress: scrollYProgress2 } = useScroll({
        target: fContainer,
        offset: ["start start", "end end"]
    })

    const sContainer = useRef<any>();
    const { scrollYProgress: scrollYProgress3 } = useScroll({
        target: sContainer,
        offset: ["start start", "end end"]
    })


    useEffect(() => {
        const lenis = new Lenis()

        function raf(time: any) {
            lenis.raf(time)
            requestAnimationFrame(raf)
        }

        requestAnimationFrame(raf)
    }, [])

    return (
        <main ref={container} className="relative h-[1075vh] pt-[15vh] w-full">
            <div ref={fContainer}>
                <AnimatedSection
                    scrollYProgress={scrollYProgress2}
                    className={`sticky top-0 h-screen`}
                    opacity={[0, 1]}
                    rotate={[0, -10]} scale={[1, 0.8]}>
                    <Hero1 />
                </AnimatedSection>

                <AnimatedSection
                    scrollYProgress={scrollYProgress}
                    className="relative h-screen"
                    rotate={[0, 0]} scale={[1, 1]}
                >
                    <Introduction />
                </AnimatedSection>
            </div>
            <div ref={sContainer} className="h-[230vh]">
                <AnimatedSection
                    scrollYProgress={scrollYProgress3}
                    className={`sticky top-0 h-screen`}
                    opacity={[0, 1]}
                    rotate={[0, -10]} scale={[1, 0.8]}>
                    <KeldCompanySection />
                </AnimatedSection>
                <AnimatedSection
                    scrollYProgress={scrollYProgress}
                    className="relative h-[130vh]"
                    rotate={[0, 0]} scale={[1, 1]}
                >
                    <Container className="h-[30vh]">
                        <Title
                            withLine={false}
                            className="text-2xl text-center xl:px-[200px] px-[50px] py-10"
                            text={t('RealEstateInfrastructureT')}
                        />
                    </Container>
                    <AnimatedSection
                        scrollYProgress={scrollYProgress}
                        className="relative h-screen"
                        rotate={[0, 0]} scale={[1, 1]}
                    >
                        <RealEstateInfrastructure />
                    </AnimatedSection>
                </AnimatedSection>
            </div>

            <AnimatedSection
                scrollYProgress={scrollYProgress}
                className="relative h-[70vh]"
                rotate={[0, 0]} scale={[1, 1]}>
                <CommercialRealEstate />
            </AnimatedSection>
            <AnimatedSection
                scrollYProgress={scrollYProgress}
                className="relative h-screen"
                rotate={[0, 0]} scale={[1, 1]}>
                <ResidentialCommunities />
            </AnimatedSection>
            <AnimatedSection
                scrollYProgress={scrollYProgress}
                className="relative h-screen"
                rotate={[0, 0]} scale={[1, 1]}>
                <SectionWithBgImg1 />
            </AnimatedSection>
            <AnimatedSection
                scrollYProgress={scrollYProgress}
                className="relative h-screen"
                rotate={[0, 0]} scale={[1, 1]}>
                <SectionWithBgImg2 />
            </AnimatedSection>

            <AnimatedSection
                scrollYProgress={scrollYProgress}
                className="relative h-screen"
                rotate={[0, 0]} scale={[0.8, 1]}>
                <InvestmentsPortfolio />
            </AnimatedSection>

            <AnimatedSection
                scrollYProgress={scrollYProgress}
                className="relative h-[160vh]"
                rotate={[0, 0]} scale={[0.8, 1]}>
                <Container className="h-[30vh]">
                    <Title text={t("aiT")} withLine={false}
                        className="text-2xl text-center xl:px-[200px] px-[50px] py-10"
                    />
                </Container>
                <Ai />
            </AnimatedSection>
        </main >
    )
}
