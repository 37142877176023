import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            // welcome
            "welcome": "Real Estate Company Innovating the Real Estate Sector",

            // contact
            "helpTitle": "How can we help?",
            "helpSub": "We usually respond in a few hours",
            "send": "Send Message",
            "fullName": "Full Name",
            "fullNameReq": "Full name is required",
            "email": "Email Address",
            "emailReq": "Enter your email",
            "emailValid": "Please enter a valid email",
            "yourMessage": "Your Message",
            "yourMessageReq": "Enter your Message",
            "goBack": "Go back",
            "sentSuccess": "Message sent successfully",
            "sentError": "Oops, Something went wrong!",

            "intro": "Introduction",
            "intro1": "DEVELOPING REAL ESTATE PROJECTS.",
            "intro2": "INVESTING IN COMPANIES AND FUNDS.",
            "intro3": "DEVELOPING AI FOR THE REAL ESTATE SECTOR.",

            "KeldCompanySectionT": "KELD REAL ESTATE COMPANY",
            "KeldCompanySectionD1": "is a prominent player in the Saudi Arabian real estate market, renowned for its innovative projects and commitment to excellence.",
            "KeldCompanySectionD2": "The company has successfully undertaken various developments throughout the country. contributing to the growth and transformation of Saudi Arabia's real estate sector.",

            "RealEstateInfrastructure": "REAL ESTATE INFRASTRUCTURE",
            "RealEstateInfrastructureT": "Here are some key aspects of KELD' s work in Saudi Arabia:",
            "RealEstateInfrastructureD": "KELD actively participates in the development of real estate infrastructure across Saudi Arabia.",
            "RealEstateInfrastructureD2": "The company collaborates with government entities and private organizations to construct essential infrastructure such as roads, bridges, and transportation systems.",
            "RealEstateInfrastructureD3": "By focusing on infrastructure development, KELD aims to enhance connectivity. promote economic growth, and create sustainable urban environments in Saudi Arabia.",

            "commercialRealEstateT": "COMMERCIAL REAL ESTATE",
            "commercialRealEstateD": "KELD recognizes the importance of commercial properties in supporting business growth and economic diversification.",
            "commercialRealEstateD2": "The company has successfully developed numerous commercial projects in Saudi Arabia, including office buildings, shopping malls, and mixed-use complexes.",
            "commercialRealEstateD3": "These properties are designed to meet the evolving needs of businesses, providing modern facilities, flexible spaces, and an environment conducive to productivity and success.",

            "ResidentialCommunitiesT": "RESIDENTIAL COMMUNITIES",
            "ResidentialCommunitiesD1": "KELD is committed to creating exceptional residential communities that cater to the growing housing needs in Saudi Arabia.",
            "ResidentialCommunitiesD2": "The company developing a range of residential projects, including apartment complexes, villas, and gated communities.",
            "ResidentialCommunitiesD3": "These developments prioritize comfort, security, and community well-being, offering residents a high quality of life and access to essential amenities and recreational facilities.",

            "SectionWithBgImg1": "In all its projects, KELD Real Estate Company adheres to the highest standards of design, construction, and sustainability.",
            "SectionWithBgImg2": "The company integrates modern technologies and eco-friendly practices to create environmentally conscious and energy-efficient developments.",
            "SectionWithBgImg3": "By actively participating in the development of real estate infrastructure, commercial properties, and residential communities, KELD contributes to the overall growth and progress of Saudi Arabia's real estate sector.",
            "SectionWithBgImg4": "The company's dedication to quality, innovation, and sustainable practices has positioned it as a trusted and influential player in the Saudi Arabian real estate market.",

            "investments": "INVESTMENTS PORTFOLIO",
            "investmentsDesc": "KELD Real Estate Company's investment portfolio strategically combines assets, equity investments, and innovative technologies to generate sustainable returns and contribute to the growth.",

            "aiT": "Leveraging artificial intelligence (AI) to revolution the real estate industry:",
            "aiP": "KELD Real Estate Company is at the forefront of leveraging artificial intelligence (AI) to revolutionize the real estate industry in numerous ways. By harnessing the power of AI, KELD is transforming traditional practices, enhancing decision-making processes, and improving overall efficiency. Here are some key ways in which KELD is utilizing Al Property Valuation and Market Analysis: KELD employs Al algorithms to analyze vast amounts of data, including historical property prices, market trends, and economic indicators. This enables the company to accurately assess property values, identify investment opportunities, and make informed decisions regarding acquisitions or salles. Predictive Analytics: Through Al-powered predictive analytics, KELD can anticipate market fluctuations, property demand, and investment performance. This allows the company to optimize its investment strategies, identify potential risks, and make proactive adjustments to its portfolio.",
        }
    },
    ar: {
        translation: {
            // welcome
            "welcome": 'شركة عقارية متخصصة في القطاع العقاري',

            // contact
            "helpTitle": "كيف يمكننا المساعدة؟",
            "helpSub": "نرد عادة في غضون ساعات قليلة",
            "send": "إرسال",
            "fullName": "الإسم كامل",
            "fullNameReq": "الإسم مطلوب",
            "email": "البريد الإلكتروني",
            "emailReq": "البريد الإلكتروني مطلوب",
            "emailValid": "من فضلك ادخل بريد إلكتروني صحيح",
            "yourMessage": "رسالتك",
            "yourMessageReq": "رسالتك مطلوبة",
            "goBack": "الرجوع للخلف",
            "sentSuccess": "تم إرسال الرسالة بنجاح",
            "sentError": "!شيء ما حدث بشكل خاطئ",

            "intro": "مقدمة",
            "intro1": "تطوير المشاريع العقارية.",
            "intro2": "الاستثمار في الشركات والاموال.",
            "intro3": "تطوير الذكاء الاصطناعي في قطاع العقارات.",

            "KeldCompanySectionT": "شركة كيلد العقارية",
            "KeldCompanySectionD1": "هي لاعب بارز في سوق العقارات في المملكة العربية السعودية، وتشتهر بمشاريعها المبتكرة والتزامها بالتميز.",
            "KeldCompanySectionD2": "وقد نفذت الشركة بنجاح العديد من التطورات في جميع أنحاء البلاد. والمساهمة في نمو وتحول القطاع العقاري في المملكة العربية السعودية.",

            "RealEstateInfrastructure": "البنية التحتية العقارية",
            "RealEstateInfrastructureT": "فيما يلي بعض الجوانب الرئيسية لعمل كيلد في المملكة العربية السعودية:",
            "RealEstateInfrastructureD": "تشارك كيلد بنشاط في تطوير البنية التحتية العقارية في جميع أنحاء المملكة العربية السعودية.",
            "RealEstateInfrastructureD2": "تتعاون الشركة مع الجهات الحكومية والمنظمات الخاصة لبناء البنية التحتية الأساسية مثل الطرق والجسور وأنظمة النقل.",
            "RealEstateInfrastructureD3": "ومن خلال التركيز على تطوير البنية التحتية، كيلد إلى تعزيز الاتصال. تعزيز النمو الاقتصادي، وخلق بيئات حضرية مستدامة في المملكة العربية السعودية.",

            "commercialRealEstateT": "العقارات التجارية:",
            "commercialRealEstateD": "تدرك كيلد أهمية العقارات التجارية في دعم نمو الأعمال والتنويع الاقتصادي.",
            "commercialRealEstateD2": "ونجحت الشركة في تطوير العديد من المشاريع التجارية في المملكة العربية السعودية، بما في ذلك مباني المكاتب ومراكز التسوق والمجمعات متعددة الاستخدامات.",
            "commercialRealEstateD3": "تم تصميم هذه العقارات لتلبية الاحتياجات المتطورة للشركات، وتوفير مرافق حديثة، ومساحات مرنة، وبيئة مواتية للإنتاجية والنجاح.",

            "ResidentialCommunitiesT": "المجتمعات السكنية",
            "ResidentialCommunitiesD1": "تلتزم كيلد بإنشاء مجتمعات سكنية استثنائية تلبي احتياجات الإسكان المتزايدة في المملكة العربية السعودية.",
            "ResidentialCommunitiesD2": "تقوم الشركة بتطوير مجموعة من المشاريع السكنية، بما في ذلك المجمعات السكنية والفيلات والمجتمعات المسورة.",
            "ResidentialCommunitiesD3": "تعطي هذه التطورات الأولوية للراحة والأمن ورفاهية المجتمع، مما يوفر للمقيمين نوعية حياة عالية وسهولة الوصول إلى المرافق الأساسية والمرافق الترفيهية.",

            "SectionWithBgImg1": "تلتزم شركة كيلد العقارية في جميع مشاريعها بأعلى معايير التصميم والبناء والاستدامة.",
            "SectionWithBgImg2": "تدمج الشركة التقنيات الحديثة والممارسات الصديقة للبيئة لإنشاء مشاريع صديقة للبيئة وموفرة للطاقة.",
            "SectionWithBgImg3": "من خلال المشاركة الفعالة في تطوير البنية التحتية العقارية والعقارات التجارية والمجتمعات السكنية، تساهم كيلد في النمو الشامل والتقدم في قطاع العقارات في المملكة العربية السعودية.",
            "SectionWithBgImg4": "إن تفاني الشركة في الجودة والابتكار والممارسات المستدامة جعلها لاعباً موثوقاً ومؤثراً في سوق العقارات السعودي.",

            "investments": "محفظة الاستثمارات",
            "investmentsDesc": "تجمع المحفظة الاستثمارية لشركة كيلد العقارية بشكل استراتيجي بين الأصول واستثمارات الأسهم والتقنيات المبتكرة لتحقيق عوائد مستدامة والمساهمة في النمو.",

            "aiT": "الاستفادة من الذكاء الاصطناعي  لإحداث ثورة في صناعة العقارات:",
            "aiP": "تعد كيلد شركة العقارية في طليعة الاستفادة من الذكاء الاصطناعي لإحداث ثورة في صناعة العقارات بطرق عديدة. ومن خلال تسخير قوة الذكاء الاصطناعي، تعمل كيلد على تحويل الممارسات التقليدية، وتعزيز عمليات صنع القرار، وتحسين الكفاءة العامة. فيما يلي بعض الطرق الرئيسية التي تستخدمها كيلد لتقييم العقارات وتحليل السوق: تستخدم كيلد خوارزميات لتحليل كميات هائلة من البيانات، بما في ذلك أسعار العقارات التاريخية واتجاهات السوق والمؤشرات الاقتصادية. وهذا يمكّن الشركة من تقييم قيم العقارات بدقة، وتحديد فرص الاستثمار، واتخاذ قرارات مستنيرة فيما يتعلق بعمليات الاستحواذ أو البيع. التحليلات التنبؤية: من خلال التحليلات التنبؤية المدعومة من ، يستطيع توقع تقلبات السوق والطلب على العقارات وأداء الاستثمار. يتيح ذلك للشركة تحسين استراتيجياتها الاستثمارية وتحديد المخاطر المحتملة وإجراء تعديلات استباقية على محفظتها الاستثمارية.",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage['lang'] ?? "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;