import { useContext } from 'react';
import { ThemeContext } from '../../Context/ThemeContext';
import { Link } from 'react-router-dom';

const className = {
    logo: `flex items-center space-x-2 w-[100px] h-[45px] font-medium text-indigo-500 dark:text-gray-100`
}

export const Logo = () => {
    const themeContext = useContext(ThemeContext);

    if (!themeContext) {
        throw new Error('ThemeContext must be used within a ThemeProvider');
    }

    const { theme } = themeContext;
    const logo = theme === 'dark' ? '/images/logo/logoWhite.png' : '/images/logo/logoBlack.png'
    return (
        <Link to={'/'}>
            <span className={className.logo}>
                <img
                    src={logo}
                    alt="keld"
                    className="w-full h-full"
                />
            </span>
        </Link>
    )
}
