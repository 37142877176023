import { useContext } from "react";
import { ThemeContext } from "../Context/ThemeContext";

export const useBackground = () => {
    const themeContext = useContext(ThemeContext);

    if (!themeContext) {
        throw new Error('Context must be used within a ThemeProvider');
    }
    const { theme } = themeContext;
    const backgroundColor = theme === 'dark' ? 'rgb(23,23,23)' : '#F7F7F7'

    return { backgroundColor }
}