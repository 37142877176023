import { SectionWithBgImg } from "./Common"
import { useTranslation } from "react-i18next"

export const SectionWithBgImg2 = () => {
    const { t } = useTranslation()

    return (
        <SectionWithBgImg bgImage="/images/home/image-008.jpg">
            <span>
                {t("SectionWithBgImg3")}
            </span>
            <span>
                {t("SectionWithBgImg4")}
            </span>
        </SectionWithBgImg>
    )
}
