interface Component {
    children: React.ReactNode;
    className?: string;
    padding?: string,
}

const className = {
    containerSection: `w-full flex flex-wrap lg:pt-0 pt-[5vh] h-screen
     flex-row items-center xl:px-[200px] px-[50px]`,
    h1: `font-bold leading-snug tracking-tight text-gray-800
    lg:leading-tight xl:leading-tight dark:text-white`,
    p: `py-5 leading-normal text-gray-500 flex flex-col gap-4 sm:text-xl text-sm
    lg:text-xl xl:text-2xl dark:text-gray-300`,
    img: `flex items-center justify-center w-full lg:w-1/2 object-cover`,
}

export const ContainerSection = (props: Readonly<Component>) => {

    const containerClassName = `${className.containerSection} ${props.className}`

    return (
        <div className={`${containerClassName}`} style={{ padding: props.padding ? props.padding : '' }}>
            {props.children}
        </div>
    );
}

export const Title = ({
    text,
    textLg = false,
    className: parentClass,
    withLine = true
}: {
    text: string,
    textLg?: boolean,
    className?: string,
    withLine?: boolean
}) => {
    const size =
        textLg ?
            'text-4xl xl:text-6xl lg:text-4xl'
            :
            'text-2xl xl-text-3xl lg-text-2xl'

    return (
        <>
            <h1 className={`${className.h1} ${parentClass} ${size}`}>
                {text}
                {withLine ? <div className="w-[20%] h-[10px] bg-yellow-500 mt-1"></div> : <></>}
            </h1>
        </>
    )
}

export const P = ({ children, className: parentClass }: Readonly<Component>) => {
    return (
        <p className={`${className.p} ${parentClass}`}>
            {children}
        </p >
    )
}

export const Img = ({ src, className: parentClass, width }: { src: string, className?: string; width?: string }) => {
    return (
        <img
            className={`${className.img} ${parentClass}`}
            alt={src}
            src={src}
            style={{ width }}
            loading="lazy"
        />
    )
}

const className2 = {
    containerImage: `
    bg-cover bg-fixed bg-center h-screen bg-no-repeat
    flex flex-wrap w-[100%] mx-auto lg:gap-10 lg:flex-nowrap`,
    container: "bg-black opacity-70 w-full",
    p: `opacity-100 text-lg sm:text-lg leading-normal text-white px-10 lg:px-40
    lg:text-2xl xl:text-3xl flex-col items-center justify-center h-full flex gap-10`,
}

type ChildrenProp = {
    children: React.ReactNode;
    bgImage: string
}

export const SectionWithBgImg = ({ children, bgImage }: ChildrenProp) => {
    return (
        <div
            style={{ backgroundImage: `url(${bgImage})` }}
            className={className2.containerImage}>
            <div className={className2.container}  >
                <p className={className2.p}>
                    {children}
                </p>
            </div>
        </div >
    )
}
