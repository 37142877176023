const className = {
    'copy': 'text-sm text-center pt-16 text-gray-600 dark:text-gray-400'
}

export const CopyRight = () => {
    return (
        <div className={className.copy}>
            Copyright © {new Date().getFullYear()}
        </div>
    )
}
