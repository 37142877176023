import { DisclosurePanel } from "@headlessui/react";
import { ThemeChanger } from "../DarkSwitch";
import { Language } from "./Language/Language";
import { Link } from "react-router-dom";
import { Container } from "../Common/Containers/Container";

const navigation = [
    "About Us",
    "Contact Us",
];

export const Menu = () => {
    return (
        <div className="hidden text-center lg:flex lg:items-center">
            <ul className="items-center justify-end flex-1 pt-6 list-none lg:pt-0 lg:flex">
                {/* {navigation.map((menu, index) => (
                    <li className="mr-3 nav__item" key={index}>
                        <Link
                            to={'/'}
                            className="inline-block px-4 py-2 text-lg font-normal text-gray-800 no-underline rounded-md dark:text-gray-200 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:focus:bg-gray-800">
                            {menu}
                        </Link>
                    </li>
                ))} */}
            </ul>
        </div>
    )
}


export const SmallMenu = () => {
    return (
        <DisclosurePanel className="p-10 z-50 flex flex-col gap-2 w-full lg:hidden items-center">
            {/* {navigation.map((item, index) => (
                <Link
                    to={'/'}
                    key={index}
                    className="w-full px-4 py-2 text-center ml-4 text-gray-500 rounded-md dark:text-gray-300 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 dark:focus:bg-gray-800 focus:outline-none">
                    {item}
                </Link>
            ))} */}
            <div>
                <div className="flex gap-5 w-full">
                    <Language />
                    <ThemeChanger />
                </div>
            </div>
        </DisclosurePanel>
    )
}