import { useContext } from "react"
import { motion, useTransform } from "framer-motion";
import { LangContext } from "../../../Context/LangContext";
import { useBackground } from "../../../hooks/useBackground";

interface ContainerProps {
    children: React.ReactNode;
    className?: string;
}

export const Container = (props: Readonly<ContainerProps>) => {
    const { backgroundColor } = useBackground()

    const langContext = useContext(LangContext);

    if (!langContext) {
        throw new Error('Context must be used within a ThemeProvider');
    }
    const { lang } = langContext;

    return (
        <div
            dir={lang === 'ar' ? 'rtl' : 'ltr'}
            style={{ backgroundColor }}>
            {props.children}
        </div>
    );
}

interface AnimatedSectionProps {
    children: React.ReactNode;
    className?: string;
    scrollYProgress: any;
    scale: number[],
    rotate: number[],
    opacity?: number[],
}

export const AnimatedSection = ({
    scrollYProgress,
    children,
    className,
    rotate: rotateSection,
    scale: scaleSection,
    opacity: opacitySection,
}: AnimatedSectionProps) => {
    const scale = useTransform(scrollYProgress, [0, 1], scaleSection);
    const rotate = useTransform(scrollYProgress, [0, 1], rotateSection);
    const opacity = useTransform(scrollYProgress, [1, 0], opacitySection ?? [1, 1]);

    return (
        <motion.div style={{ scale, rotate, opacity }}
            className={className} >
            {children}
        </motion.div >
    )
}

